<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between align-items-center card-header">
      <h2 v-if="!id" class="mb-0">New Instructor</h2>
      <h2 v-else class="mb-0">Edit Instructor</h2>

      <CRow class="align-items-center card-header__row">
        <CSelect class="mb-0 mr-3" :value="current_language" :options="[
          { label: 'English', value: 'en' },
          { label: 'Spanish', value: 'es' },
          { label: 'German', value: 'de' },
          { label: 'Dutch', value: 'nl' }
        ]" @update:value="updateLanguage">
        </CSelect>

        <CButton @click="resetPassword" color="primary">Reset Password</CButton>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.id || !this.user_loading">
        <CInput label="First Name" v-model="new_instructor.first_name" autocomplete="first-name"
          :addInputClasses="{ 'is-invalid': hasError('first_name') }" :invalid-feedback="getError('first_name')">
        </CInput>
        <CInput label="Last Name" v-model="new_instructor.last_name" autocomplete="last-name"
          :addInputClasses="{ 'is-invalid': hasError('last_name') }" :invalid-feedback="getError('last_name')"></CInput>
        <CInput label="Email" v-model="new_instructor.email" autocomplete="email" :disabled="!isEnglish"
          :addInputClasses="{ 'is-invalid': hasError('email') }" :invalid-feedback="getError('email')"></CInput>
        <div class="mb-3">
          <label>Phone</label>
          <vue-tel-input v-model="new_instructor.phone" mode="international" :disabled="!isEnglish"></vue-tel-input>
          <div class="invalid-feedback d-block" v-if="hasError('phone')">
            {{ getError("phone") }}
          </div>
        </div>
        <CTextarea label="Instructor Biography" v-model="new_instructor.description" autocomplete="description"
          :addInputClasses="{ 'is-invalid': hasError('description') }" :invalid-feedback="getError('description')">
        </CTextarea>
        
        <!--Image-->
        <div class="row my-3">
          
          <div class="col-md-6">
            <img :src="new_instructor.image" alt="" width="300rem">
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <h4>Original Image</h4>
                
                <div>
                  <cropper ref="cropper" class="upload_example-cropper" :src="image.src" @change="onChange" />
                </div>

                <div>
                  <CButton class="button" type="button" @click="$refs.file.click()">
                    <input type="file" ref="file" @change="loadImage($event)" accept="image/*" />
                    Load image
                  </CButton>
                </div>

                <div class="mt-3">
                  <CButton class="button" type="button" @click="reset">
                    Clear image
                  </CButton>
                </div>

              </div>

              <div class="col-md-6">
                <h4>Cropped Image</h4>
                <preview :image="result.image" style="width: 10rem; height: 10rem;" :coordinates="result.coordinates" />
              </div>

            </div>
          </div>

        </div>
        
        <CButton type="submit" color="primary" :disabled="!isEdited">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner style="width:2rem;height:2rem;" color="primary" grow />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import { Cropper, Preview } from "vue-advanced-cropper";
import 'vue-advanced-cropper/dist/style.css';

function getMimeType(file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}


export default {
  name: "EditInstructor",
  components: {
    Cropper,
    Preview
  },
  mixins: [hasApiValidation],
  props: ["id"],
  beforeRouteLeave(to, from, next) {
    if (this.isEdited) {
      const answer = window.confirm(
        "You have unsaved changes. Are you sure you want to leave the page?"
      );
      if (answer) {
        return next();
      } else {
        return next(false);
      }
    } else {
      return next();
    }
  },
  data() {
    return {
      result: {
        coordinates: null,
        image: null
      },
      image: {
        src: null,
        type: null
      },
      current_language: "en",
      roles: [],
      new_instructor: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        description: "",
        image: "",
        image_base64: null
      },
      loading_roles: true,
      user_loading: false,
      translations: {},
      originData: {}
    };
  },
  mounted() {
    this.user_loading = true;
    this.fetchUser(this.id);
  },
  computed: {
    isEnglish() {
      return this.current_language === "en";
    },
    isEdited() {
      const parser = data => JSON.stringify(data);
      return parser(this.originData) !== parser(this.new_instructor);
    }
  },
  methods: {
    reset() {
      this.image = {
        src: null,
        type: null
      };
      this.result = {
        coordinates: null,
        image: null
      };
      this.new_instructor.image_base64 = null;
    },
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image
      };
      const { canvas } = this.$refs.cropper.getResult();
      const baseImage64 = canvas.toDataURL(this.image.type);

      this.new_instructor.image_base64 = baseImage64;
    },
    loadImage(event) {
      const { files } = event.target;

      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type)
          }
        };
        reader.readAsArrayBuffer(files[0]);
      }

    },
    destroyed() {
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }
    },
    setOriginData() {
      this.originData = JSON.parse(JSON.stringify(this.new_instructor));
    },
    setUserData(data) {
      if (this.isEnglish) {
        this.new_instructor.email = data.data.email;
        this.new_instructor.phone = data.data.phone;
      }

      this.new_instructor.id = data.data.id;
      this.new_instructor.first_name = data.data.first_name;
      this.new_instructor.last_name = data.data.last_name;
      this.new_instructor.description = data.data.description;
      this.translations = {
        ...data.data.translations,
        en: {
          first_name: data.data.first_name,
          last_name: data.data.last_name,
          description: data.data.description
        }
      };

      this.new_instructor.image = decodeURIComponent(data.data.image);

      this.setOriginData();
    },
    fetchUser(id) {
      this.$http
        .get("/instructors/" + id)
        .then(({ data }) => {
          this.setUserData(data);
        })
        .finally(() => {
          this.user_loading = false;
        });
    },
    updateData(data) {
      this.new_instructor.roles = data;
    },
    updateLanguage(value) {
      const prevLanguage = this.current_language;

      if (this.isEdited) {
        const answer = window.confirm(
          "You have unsaved changes! Are you sure you want to leave the page?"
        );
        if (!answer) {
          this.current_language = null;
          this.$nextTick(() => {
            this.current_language = prevLanguage;
          });
          return;
        }
      }

      this.current_language = value;

      const currentLanguageData = this.translations[value] || {};

      this.new_instructor.first_name = currentLanguageData?.first_name || "";
      this.new_instructor.last_name = currentLanguageData?.last_name || "";
      this.new_instructor.description = currentLanguageData?.description || "";

      this.setOriginData();
    },
    logPhone(e) {
      console.log("loeg", e);
    },
    updatePhone(e) {
      console.log(e);
    },
    submit() {
      this.update();
    },
    resetPassword() {
      this.$http
        .put("/instructors/" + this.id + "/password")
        .then(data => {
          this.$noty.success("New password has been sent to the user's email");
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
        });
    },
    update() {
      this.setErrors({});
      let action;
      if (this.isEnglish) {
        action = this.$http.put("/instructors/" + this.id, this.new_instructor);
      } else {
        action = this.$http.post(
          `/instructors/${this.id}/update-or-create-translations`,
          {
            language: this.current_language,
            first_name: this.new_instructor.first_name,
            last_name: this.new_instructor.last_name,
            description: this.new_instructor.description
          }
        );
      }

      action
        .then(({ data }) => {
          this.setUserData(data);
          this.updateLanguage(this.current_language);
          this.$noty.success("Changes have been saved successfully");
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        });
    }
  }
};
</script>

<style scoped>
.upload-example-cropper {
  border: solid 1px #eee;
  /* min-height: 300px;
  max-height: 500px;  */
  width: 100%;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  background: #007bff;
  cursor: pointer;
  transition: background 0.5s;
  width: 100%;
  text-align: center;
}

.button:hover {
  background: #1d5ca0;
}

.button input {
  display: none;
}
</style>

<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 class="mb-0">Invite Instructor</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off">
        <CInput label="First Name" v-model="new_instructor.first_name" autocomplete="first-name"
          :addInputClasses="{ 'is-invalid': hasError('first_name') }" :invalid-feedback="getError('first_name')">
        </CInput>
        <CInput label="Last Name" v-model="new_instructor.last_name" autocomplete="last-name"
          :addInputClasses="{ 'is-invalid': hasError('last_name') }" :invalid-feedback="getError('last_name')"></CInput>
        <CInput label="Email" v-model="new_instructor.email" autocomplete="email"
          :addInputClasses="{ 'is-invalid': hasError('email') }" :invalid-feedback="getError('email')"></CInput>

        <!--Image-->
        <div class="row my-3">
          <div class="col-md-6">
            <h4>Original Image</h4>
            <cropper ref="cropper" class="upload-example-cropper" :src="image.src" @change="onChange" />
            <CButton class="button" type="button" @click="$refs.file.click()">
              <input type="file" ref="file" @change="loadImage($event)" accept="image/*">
              Load image
            </CButton>
          </div>
          <div class="col-md-6">
            <h4>Cropped Image</h4>
            <preview :width="300" :height="300" :image="result.image" :coordinates="result.coordinates" />
          </div>
        </div>

        <CButton type="submit" color="primary">Save</CButton>
      </form>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

function getMimeType(file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

export default {
  name: "InviteInstructor",
  components: {
    Cropper,
    Preview
  },
  mixins: [hasApiValidation],
  data() {
    return {
      roles: [],
      result: {
        coordinates: null,
        image: null
      },
      image: {
        src: null,
        type: null
      },
      new_instructor: {
        first_name: '',
        last_name: '',
        email: '',
        image_base64: ''
      },
    }
  },
  mounted() {
  },
  methods: {
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image
      };
      const { canvas } = this.$refs.cropper.getResult();
      const base64Image = canvas.toDataURL(this.image.type);


      this.new_instructor.image_base64 = base64Image;
    },
    loadImage(event) {
      const { files } = event.target;

      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }

        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
          };
        };

        reader.readAsArrayBuffer(files[0]);
      }
    },
    destroyed() {
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src)
      }
    },
    submit() {
      this.save();
    },
    save() {
      this.setErrors({});
      this.$http.post('/instructors', this.new_instructor).then((data) => {
        this.$router.push({ name: 'Instructors' }).then(() => {
          this.$noty.success('Created');
        })
      }).catch(({ response }) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
  },
}
</script>

<style scoped>
.upload-example-cropper {
  border: solid 1px #eee;
  min-height: 300px;
  max-height: 500px;
  width: 100%;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  background: #007bff;
  cursor: pointer;
  transition: background 0.5s;
  width: 100%;
  text-align: center;
}

.button:hover {
  background: #1d5ca0;
}

.button input {
  display: none;
}
</style>
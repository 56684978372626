<template>
  <CModal
      :show.sync="showModal"
      centered
      @update:show="closeModal"
      title="Set User Subscription"
  >
    <CCardBody class="pt-0">
      <CSelect :value="type" :options="statuses" label="Subscription"
               @update:value="updateData($event)"></CSelect>
      <div class="d-flex justify-content-center" v-if="set_date">
        <vc-date-picker v-model="expire_at" mode="dateTime" :min-date='new Date()'/>
      </div>
      <div class="d-flex mt-3 align-items-center justify-content-center">
        <CButton color="secondary" class="mx-auto text-uppercase"
                 @click="showModal = false"
        >
          Cancel
        </CButton>
        <CButton color="primary" class="mx-auto text-uppercase btn_yes"
                 @click="save()"
        >
          Set
        </CButton>
      </div>
    </CCardBody>
    <template #footer-wrapper>
      <div class="d-flex my-0 align-items-center justify-content-center">
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "AdminSubscriptionModal",
  data() {
    return {
      user: null,
      type: 0,
      showModal: false,
      loading: true,
      statuses: [
        {value: 0, label: 'None'},
        {value: 1, label: 'Basic'},
        {value: 2, label: 'Full'},
        {value: 3, label: 'SpinStudio'},
      ],
      expire_at: new Date(),
      expire_at_min: new Date(),
      set_date: false,
    }
  },
  methods: {
    updateData(val) {
      this.type = val;
      this.set_date = !!val;
      if (!val) {
        this.expire_at = null;
      }
      if(val && !this.expire_at){
        this.expire_at = new Date();
      }
    },
    open(user) {
      this.showModal = true;
      this.user = Object.assign({}, user);
      this.expire_at = this.user.admin_subscription_expire_at
        ? this.$moment.utc(this.user.admin_subscription_expire_at).local().toDate()
        : null;

      this.type = this.user.admin_subscription_type;
      this.set_date = !!this.type;
    },
    closeModal() {
      this.$emit('modal:close');
      document.querySelector('body').classList.remove('modal_open')
    },
    save() {
     
      const expire_at_utc = this.expire_at
          ? this.$moment(this.expire_at).utc().format("YYYY-MM-DD HH:mm:ss")
          : null;

      this.axios.put('/users/' + this.user.id + '/admin_subscription', {type: this.type, expire_at: expire_at_utc})
          .then(() => {
            this.$noty.success('Updated!');
            this.$emit('updated')
          })
          .catch(() => {
            this.$noty.error('Oops, something went wrong!');
          })
          .finally(() => {
            this.showModal = false;
            this.closeModal();
          })
    }
  },
  computed: {}
}
</script>

<style scoped>
</style>

// src/helpers/dateHelper.js

// Import the Temporal API from the polyfill
import { Temporal } from '@js-temporal/polyfill';

/**
 * Determines whether to use 24-hour format based on the user's language.
 * @param {string} [userLang=navigator.language || "en-US"] - The user's language.
 * @returns {boolean} True if 24-hour format should be used, false if 12-hour format is preferred.
 */
export function use24HourFormat(userLang = navigator.language || "en-US") {
  // For example, if the language starts with "en", we assume 12-hour format.
  // Otherwise, use 24-hour format.
  return !userLang.startsWith('en');
}

/**
 * Returns formatting options based on the user's language and whether to include time.
 * @param {string} userLang - The user's language (e.g., "en-US" or "es-ES").
 * @param {boolean} includeTime - Whether to include hour and minute.
 * @returns {object} An options object for formatting date/time.
 */
export function getFormatOptions(userLang, includeTime = false) {
  // Basic options: numeric year, two-digit month and day.
  const baseOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  if (includeTime) {
    return {
      ...baseOptions,
      hour: '2-digit',
      minute: '2-digit',
      // Use 12-hour format if not using 24-hour format.
      hour12: !use24HourFormat(userLang)
    };
  }
  return baseOptions;
}

/**
 * Converts a date to a Temporal.PlainDate object.
 * @param {Date|string} date - A Date object or date string.
 * @returns {Temporal.PlainDate} A PlainDate representing the date.
 */
export function toPlainDate(date) {
  // Convert the date to an ISO string and extract the date part (YYYY-MM-DD)
  const isoDate = new Date(date).toISOString().split("T")[0];
  return Temporal.PlainDate.from(isoDate);
}

/**
 * Converts the given utcDate to a valid ISO 8601 string.
 * If utcDate is a string and includes "UTC" in the format "YYYY-MM-DD HH:mm UTC",
 * it transforms it to "YYYY-MM-DDTHH:mmZ". Otherwise, it attempts to convert it to ISO using Date.
 * @param {Date|string} utcDate - The UTC date to convert.
 * @returns {string} A valid ISO 8601 date string.
 */
export function convertToISOString(utcDate) {
  if (typeof utcDate !== 'string') {
    return new Date(utcDate).toISOString();
  }

  // If already in ISO 8601 format with "Z", return it as is
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/.test(utcDate)) {
    return utcDate;
  }

  // If explicitly marked with "UTC", convert it to ISO 8601 with "Z"
  if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} UTC$/.test(utcDate)) {
    return utcDate.replace(" UTC", "").replace(" ", "T") + "Z";
  }

  // If the string contains "UTC" in another format, handle it
  if (utcDate.includes("UTC")) {
    return utcDate.replace(
      /^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2}) UTC$/,
      "$1T$2Z"
    );
  }

  // If the format is "YYYY-MM-DD HH:mm:ss", assume it is in UTC and append "Z"
  if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(utcDate)) {
    return utcDate.replace(" ", "T") + "Z";
  }

  // As a fallback, attempt to convert the date using the native Date object
  return new Date(utcDate).toISOString();
}


/**
 * Retrieves the local time zone from the browser.
 * @returns {string} The local time zone (e.g., "America/New_York").
 */
export function getLocalTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Converts a UTC date to a Temporal.ZonedDateTime in the local time zone.
 * @param {Date|string} utcDate - The UTC date to convert.
 * @returns {Temporal.ZonedDateTime} A ZonedDateTime representing the date/time in the local zone.
 */
export function toZonedDateTime(utcDate) {
  const dateStr = convertToISOString(utcDate);
  const instant = Temporal.Instant.from(dateStr);
  const timeZone = getLocalTimeZone();
  return instant.toZonedDateTimeISO(timeZone);
}

/**
 * Formats a Unix timestamp (in seconds) into a localized date string.
 * @param {number} unixTimestamp - The Unix timestamp in seconds.
 * @param {string} [userLang=navigator.language || "en-US"] - The user's language (defaults to the browser's language).
 * @returns {string} The formatted date string (with dashes instead of slashes).
 */
export function formatUnixDate(unixTimestamp, userLang = navigator.language || "en-US") {
  // Multiply by 1000 to convert the Unix timestamp (in seconds) to milliseconds
  const date = new Date(unixTimestamp * 1000);
  // Retrieve formatting options based on the user's language
  const options = getFormatOptions(userLang);
  // Format the date using the locale's date string and replace "/" with "-" for consistency
  return date.toLocaleDateString(userLang, options).replace(/\//g, "-");
}

/**
 * Formats a date into a localized string without time.
 * @param {Date|string} date - The date to format.
 * @param {string} [userLang=navigator.language] - The user's language (defaults to the browser language).
 * @returns {string} The formatted date string.
 */
export function formatDate(date, userLang = navigator.language || "en-US") {
  if (!date) return "";
  const plainDate = toPlainDate(date);
  const options = getFormatOptions(userLang);
  return plainDate.toLocaleString(userLang, options).replace(/\//g, "-");
}

/**
 * Formats a UTC date/time into a localized string with time.
 * @param {Date|string} utcDate - The UTC date/time to format.
 * @param {string} [userLang=navigator.language] - The user's language (defaults to the browser language).
 * @returns {string} The formatted date/time string.
 */
export function formatDateTime(utcDate, userLang = navigator.language || "en-US") {
  if (!utcDate) return "";
  const zonedDateTime = toZonedDateTime(utcDate);
  const options = getFormatOptions(userLang, true);
  return zonedDateTime.toLocaleString(userLang, options)
    .replace(/\//g, "-")
    .replace(",", "");
}

/**
 * Formats a UTC date/time into a localized time string (only the time).
 * @param {Date|string} utcDate - The UTC date/time to format.
 * @param {string} [userLang=navigator.language] - The user's language (defaults to the browser language).
 * @returns {string} The formatted time string.
 */
export function formatTime(utcDate, userLang = navigator.language || "en-US") {
  if (!utcDate) return "";
  const zonedDateTime = toZonedDateTime(utcDate);
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    // Use 12-hour format if not using 24-hour format, based on our helper function.
    hour12: !use24HourFormat(userLang)
  };
  return zonedDateTime.toLocaleString(userLang, timeOptions).replace(",", "");
}



<template>
  <div>
    <jodit-editor
      :value="localContent"
      :config="config"
      @input="onInput"
      
    />
  </div>
</template>

<script>
import { JoditEditor } from 'jodit-vue';
import 'jodit/es5/jodit.min.css';

export default {
  components: {
    JoditEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localContent: this.value, // Inicializa con el valor de la prop
      config: {
        readonly: false,
        toolbar: true,
        buttons: [
          'bold',
          'italic',
          'underline',
          'ol',
          'ul',
          'link',
          'image',
        ],
      },
    };
  },
  watch: {
    value(newValue) {
      // Sincroniza el contenido local con el valor de la prop
      if (this.localContent !== newValue) {
        this.localContent = newValue;
      }
    },
  },
  methods: {
    onInput(newValue) {
      // Actualiza el contenido local y emite el evento "input"
      this.localContent = newValue;
      this.$emit('input', newValue);
    },
    // onChange(newValue) {
    //   // Maneja cambios adicionales si es necesario
    //   console.log('Contenido cambiado:', newValue);
    // },
  },
};
</script>

<style scoped>
/* Estilos adicionales si es necesario */
</style>

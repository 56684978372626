<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end align-items-center">
      <h2 class="text-uppercase font-weight-bold mr-auto">Enterprise User Subscriptions</h2>
      <CButton @click="$router.push({name: 'Enterprise Users'})" class="ml-auto mr-3" color="info">
        General Info
      </CButton>
      <CButton @click="createSubscription" class="mr-3" color="primary">
        Add New
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #next_payment_at="data">
          <td>{{ $formatDateTime(data.item.next_payment_at) }}</td>
        </template>
        <template #actions="data">
          <td>
            <CButton size="sm" color="danger" class="text-white mr-2" @click="deleteSubscription(data.item.id)"
                     v-if="data.item.stripe_price === 'admin_enterprise'">Delete
            </CButton>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDeleteSubscription"></delete-confirm-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import DeleteConfirmModal from "./DeleteConfirmModal";

export default {
  name: "EnterpriseSubscriptionList",
  components: {DeleteConfirmModal},
  mixins: [externalTable],
  props: ['id'],
  data() {
    return {
      subscriptions: false,
      filters: {
        search: '',
        active: [],
      },
      statuses: [
        // {value: null, label: 'All'},
        {value: 1, label: 'Active'},
        {value: 0, label: 'Blocked'},
      ],
      fields: [
        {
          key: 'active', label: 'Status',
        },
      ],
      url: '/enterprise-users/' + this.id + '/subscriptions',
      loadingActionSubscription: null,
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    createSubscription() {
      this.$router.push({name: 'New Enterprise User Subscription'});
    },
    deleteSubscription(id){
      this.$refs.delete_modal.open(id)
    },
    confirmDeleteSubscription(id) {
      this.$http.delete('/enterprise-users/' + this.id + '/subscriptions/' + id).then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
  },
  computed: {
    c_fields() {
      let fields = [
        {
          key: 'name', label: 'Name',
        },
        {
          key: 'next_payment_at', label: 'Next Payment',
        },
        {
          key: 'quantity', label: 'Quantity',
        },
        {
          key: 'stripe_status', label: 'Status',
        },
      ];
      fields.push(
          {
            key: 'actions', label: 'Actions',
          }
      );
      return fields
    }
  },
}
</script>

<style scoped>
.fa-google {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
</style>
